import React, { useCallback, useEffect, useState } from "react";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";

export const Navigation = (props) => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolling, setScrolling] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false); // Tambahan state untuk animasi navbar

  const handleSetActive = (link) => {
    setActiveLink(link);
    setScrolling(true);
    setShowOffcanvas(false); // Close sidebar on link click
    if (link === "home") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      const element = document.getElementById(link);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          element.scrollIntoView(true);
        }, 300);
      }
    }
    setTimeout(() => setScrolling(false), 1000);
  };

  const handleScroll = useCallback(() => {
    if (scrolling) return;

    const sections = ["home", "about", "services", "portfolio", "contact"];
    const scrollPosition = window.scrollY + window.innerHeight / 2;

    let currentActiveLink = "";
    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element && element.offsetTop <= scrollPosition && element.offsetTop + element.offsetHeight > scrollPosition) {
        currentActiveLink = section;
      }
    });

    if (currentActiveLink && currentActiveLink !== activeLink) {
      setActiveLink(currentActiveLink);
    } else if (!currentActiveLink && activeLink) {
      setActiveLink("");
    }
  }, [activeLink, scrolling]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  // useEffect untuk memicu animasi navbar saat halaman dimuat
  useEffect(() => {
    setTimeout(() => setShowNavbar(true), 300); // Tunda sedikit agar animasi lebih halus
  }, []);

  return (
    <>
      <div id="home" style={{ position: "absolute", top: "0", marginTop: "10px" }}></div>
      <Navbar expand="lg" fixed="top" id="menu" className={`navbar-dark ${showNavbar ? "navbar-show" : "navbar-hide"}`}>
        <div className="container">
          <Navbar.Brand href="#home" onClick={() => handleSetActive("home")}>
            <img src="img/LOGO.png" alt="Logo" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={() => setShowOffcanvas(true)} />
          <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex">
            <Nav className="ml-auto">
              <Nav.Link href="#home" className={`page-scroll nav-link ${activeLink === "home" ? "active" : ""}`} onClick={() => handleSetActive("home")}>
                Home
              </Nav.Link>
              <Nav.Link href="#about" className={`page-scroll nav-link ${activeLink === "about" ? "active" : ""}`} onClick={() => handleSetActive("about")}>
                About
              </Nav.Link>
              <Nav.Link href="#services" className={`page-scroll nav-link ${activeLink === "services" ? "active" : ""}`} onClick={() => handleSetActive("services")}>
                Services
              </Nav.Link>
              <Nav.Link href="#portfolio" className={`page-scroll nav-link ${activeLink === "portfolio" ? "active" : ""}`} onClick={() => handleSetActive("portfolio")}>
                Portfolio
              </Nav.Link>
              <Nav.Link href="#contact" className={`page-scroll nav-link ${activeLink === "contact" ? "active" : ""}`} onClick={() => handleSetActive("contact")}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} id="offcanvasNavbar">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="ml-auto">
                <Nav.Link href="#home" className={`page-scroll nav-link ${activeLink === "home" ? "active" : ""}`} onClick={() => handleSetActive("home")}>
                  Home
                </Nav.Link>
                <Nav.Link href="#about" className={`page-scroll nav-link ${activeLink === "about" ? "active" : ""}`} onClick={() => handleSetActive("about")}>
                  About
                </Nav.Link>
                <Nav.Link href="#services" className={`page-scroll nav-link ${activeLink === "services" ? "active" : ""}`} onClick={() => handleSetActive("services")}>
                  Services
                </Nav.Link>
                <Nav.Link href="#portfolio" className={`page-scroll nav-link ${activeLink === "portfolio" ? "active" : ""}`} onClick={() => handleSetActive("portfolio")}>
                  Portfolio
                </Nav.Link>
                <Nav.Link href="#contact" className={`page-scroll nav-link ${activeLink === "contact" ? "active" : ""}`} onClick={() => handleSetActive("contact")}>
                  Contact
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </Navbar>
    </>
  );
};

export default Navigation;
