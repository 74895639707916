import emailjs from "emailjs-com";
import React, { useState } from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    
    // eslint-disable-next-line
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
    emailjs
      .sendForm("service_72g7t19", "template_slxwnm5", e.target, "JdyNWW2qoS89bHHEC")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          alert("Email berhasil terkirim!");
        },
        (error) => {
          console.log(error.text);
          alert("Terjadi kesalahan saat mengirim email.");
        }
      );
  };


  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">

                <h2>Feel free to contact us at anytime!</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-customcontact btn-lg">
                  Send Message
                </button>
              </form>

              <h3>Contact Info</h3>
                <div className="row">
                  <div className="d-flex flex-wrap">
                    <div className="col-md-3 contact-item">
                      <p>
                        <span>
                          <i className="fa fa-map-marker"></i> Address
                        </span>
                        {props.data ? props.data.address : "loading"}
                      </p>
                    </div>
                    <div className="col-md-3 contact-item">
                        {props.data ? (
                          <a
                            href={`https://wa.me/${props.data.phone.replace(/\D/g, '')}?text=${encodeURIComponent(
                              'Halo, saya tertarik untuk mengetahui lebih lanjut tentang layanan Anda.'
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>
                              <span>
                                <i className="fa fa-whatsapp"></i> Whatsapp
                              </span>
                            </p>
                          </a>
                        ) : (
                          "loading"
                        )}
                      </div>

                      <div className="col-md-3 contact-item email">
                        {props.data && props.data.email ? (
                          <a
                            href={`https://mail.google.com/mail/?view=cm&fs=1&to=${props.data.email}&su=Pertanyaan%20Mengenai%20Layanan&body=Halo,%20saya%20ingin%20mengetahui%20lebih%20lanjut%20tentang%20layanan%20yang%20Anda%20tawarkan.`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>
                              <span>
                                <i className="fa fa-envelope-o"></i> Email
                              </span>
                            </p>
                          </a>
                        ) : (
                          "loading"
                        )}
                      </div>

                  </div>
                </div>
              

            {/* <div className="col-md-4">

            <div className="google-maps">
            <h3>Meet Production's Gmaps</h3>
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.3784475430216!2d106.82138837499144!3d-6.345012493644796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ede94480afdf%3A0xbf57c449ccbf82f8!2sMEET%20Production!5e0!3m2!1sid!2sid!4v1722102261697!5m2!1sid!2sid" 
                width="100%" 
                height="250" 
                style={{border:0}} 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps MEET Production"
              ></iframe>
            </div>
          </div> */}
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
          <div className="google-maps">
            <h3>Our Location</h3>
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.3784475430216!2d106.82138837499144!3d-6.345012493644796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ede94480afdf%3A0xbf57c449ccbf82f8!2sMEET%20Production!5e0!3m2!1sid!2sid!4v1722102261697!5m2!1sid!2sid" 
                width="100%" 
                height="250" 
                style={{border:0}} 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps MEET Production"
              ></iframe>
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          
        </div>
      </div>

      <footer>
          <p>
            &copy; copyright 2024 Meet Production
          </p>
          </footer>


    </div>
  );
};