import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import data from '../data/data.json';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Portofolio = () => {
  const [portofolioData, setPortofolioData] = useState(null);
  const query = useQuery();
  const id = query.get('id');

  useEffect(() => {
    // Cari data portofolio berdasarkan id dari query parameter
    const selectedPortofolio = data.Portofolios.find(p => p.id.toString() === id);
    setPortofolioData(selectedPortofolio);
    window.scrollTo(0,0);
  }, [id]);

  if (!portofolioData) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid>
      {/* Navbar dan Logo */}
      <nav className="navbar-porto">
        <img src="/img/LOGO.png" alt="Meet Production" className="logo" />
      </nav>

      {/* Gambar Utama */}
      <div className="main-image">
        <img src={portofolioData.mainImage} alt="Main Visual" className="img-fluid" />
      </div>

      {/* Penjelasan */}
      <section className="description">
        <h1>{portofolioData.title}</h1>
        <h2>{portofolioData.date}</h2>
        <p>{portofolioData.description}</p>
      </section>

      {/* Galeri Foto */}
      <section className="gallery">
        <h3>Our Documentation</h3>
        <Row>
          {portofolioData.gallery.map((image, index) => (
            <Col xs={12} md={4} key={index}>
              <img src={image} alt={`Foto ${index + 1}`} className="gallery-img" />
            </Col>
          ))}
        </Row>
      </section>
    </Container>
  );
};

export default Portofolio;
