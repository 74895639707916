import React, { useState, useEffect } from "react";

export const About = (props) => {
  const [animateText, setAnimateText] = useState(false);
  const [animateImage, setAnimateImage] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.getElementById("about");
      const aboutTop = aboutSection.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (aboutTop < windowHeight - 100) {
        // Jika pengguna telah scroll sampai bagian about
        setAnimateText(true);
        setAnimateImage(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          {/* Text column */}
          <div className="col-xs-12 col-md-6 order-lg-2 order-md-1 order-1">
            <div className={`about-text ${animateText ? "animate-text" : ""}`}>
              <h2>Meet Production</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>The scope of work :</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>{props.data ? props.data.Why.map((d, i) => <li key={`${d}-${i}`}>{d}</li>) : "loading"}</ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>{props.data ? props.data.Why2.map((d, i) => <li key={`${d}-${i}`}> {d}</li>) : "loading"}</ul>
                </div>
              </div>
            </div>
          </div>

          {/* Image column */}
          <div className="col-xs-12 col-md-6 order-lg-1 order-md-2 order-2">
            <div className={`image-container ${animateImage ? "animate-image" : ""}`}>
              <img src="../img/about/ab2.webp" className="img-responsive" alt="About" />
              <img src="../img/about/abab.webp" className="overlay-image img-responsive" alt="Overlay" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
