import React, { useState, useEffect } from "react";
import { Carousel, Container } from "react-bootstrap";

export const Header = (props) => {
  const [animateOnce, setAnimateOnce] = useState(false);

  useEffect(() => {
    // Memulai animasi hanya sekali ketika komponen dimuat
    setTimeout(() => {
      setAnimateOnce(true);
    }, 500); // Optional delay sebelum memulai animasi
  }, []); // Hanya dijalankan sekali ketika komponen dimuat

  const images = [
    "../img/jumbotron/jb1.webp",
    "../img/jumbotron/jb2.webp",
    "../img/jumbotron/jb3.webp",
    "../img/jumbotron/jb4.webp",
    "../img/jumbotron/jb5.webp",
    "../img/jumbotron/jb6.webp"
  ];

  return (
    <header id="header">
      <Container fluid className="p-0">
        <Carousel controls={false} indicators={false} interval={3000} fade>
          {images.map((image, index) => (
            <Carousel.Item key={index}>
              <div
                className="d-block w-100 carousel-bg"
                style={{
                  backgroundImage: `url(${image})`,
                }}
              >
                <div className="overlay"></div>
                {/* Tambahkan animasi hanya saat pertama kali */}
                <div className={`intro-text ${animateOnce && index === 0 ? "animate" : ""}`}>
                  <h1>{props.data ? props.data.title : "Loading"}</h1>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                  <a href="#about" className="btn btn-custom btn-lg page-scroll">
                    Learn More
                  </a>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </header>
  );
};
